<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title"> 古茶林病虫害调查</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <!-- //  ,
           
            //   `` varchar(50) DEFAULT NULL COMMENT '',
            //   `HZRQ` date DEFAULT NULL COMMENT '',
            //   `RKSJ` datetime DEFAULT NULL COMMENT '入库时间', -->
                <el-form-item label="中文名称" prop="HCMC_ZW">
                    <el-input
                        v-model="formdata.dataDic.HCMC_ZW"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="拉丁语学名" prop="HCMC_LDY">
                    <el-input
                        v-model="formdata.dataDic.HCMC_LDY"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item label="轻度受害面积" prop="SHMJ_QD">
                    <el-input
                        v-model="formdata.dataDic.SHMJ_QD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m²</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="轻度受害数量" prop="SHSL_QD">
                    <el-input
                        v-model="formdata.dataDic.SHSL_QD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">个</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="中度受害面积" prop="SHMJ_ZD">
                    <el-input
                        v-model="formdata.dataDic.demo"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m²</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="中度受害数量" prop="SHSL_ZD">
                    <el-input
                        v-model="formdata.dataDic.SHSL_ZD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">个</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="重度受害面积" prop="SHMJ_ZHD">
                    <el-input
                        v-model="formdata.dataDic.SHMJ_ZHD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m²</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="重度受害数量" prop="SHSL_ZHD">
                    <el-input
                        v-model="formdata.dataDic.SHSL_ZHD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">个</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="合计受害面积" prop="SHMJ_HJ">
                    <el-input
                        v-model="formdata.dataDic.SHMJ_HJ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m²</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="合计受害数量" prop="SHSL_HJ">
                    <el-input
                        v-model="formdata.dataDic.SHSL_HJ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">个</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="汇总人" prop="HZR">
                    <el-input
                        v-model="formdata.dataDic.HZR"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="汇总日期" prop="HZRQ">
                    <el-date-picker
                        v-model="formdata.dataDic.HZRQ"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="分布样方" prop="SFYF">
                    <el-input
                        v-model="formdata.dataDic.SFYF"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "708",
                dataDic: {
                    HCMC_ZW: "",
                    HCMC_LDY: "",
                    SFYF: "",
                    SHMJ_QD: "",
                    SHMJ_ZD: "",
                    SHMJ_ZHD: "",
                    SHMJ_HJ: "",
                    SHSL_QD: "",
                    SHSL_ZD: "",
                    SHSL_ZHD: "",
                    SHSL_HJ: "",
                    SZ_SM: "",
                    SMXZ_SL: "",
                    SMXZ_SG: "",
                    SMXZ_XJ: "",
                    SMXZ_PJGF: "",
                    SMXZ_DXGF: "",
                    SMXZ_NBGF: "",
                    SZS: "",
                    QS: "",
                    GHDW: "",
                    GHNR: "",
                    SMTSZKMS: "",
                    DCR: "",
                    DCRQ: "",
                    HZR: "",
                    HZRQ: "",
                },
            },
            SGLXList: [],
        };
    },
    mounted() {
        // this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            // let res = await this.getAllEnumInfobytableID({
            //     bid: "1303",
            // });
            // let enumList = res || [];
            // enumList.forEach((v) => {
            //     if (v.COLUMNID === "JB") {
            //         this.SGLXList = v.dataItems;
            //     }
            // });
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
